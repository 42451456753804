<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="agency-services-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img1.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Creative Web Develop</h3>
                        <span>Web Design</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img2.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Digital Services</h3>
                        <span>Agency</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img3.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Complex Design</h3>
                        <span>Solutions</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img4.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Software Engineers</h3>
                        <span>Analysis</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img5.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Marketing Agency</h3>
                        <span>Marketing</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/agency-services/img6.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Data Analysis</h3>
                        <span>Explanation</span>
                        <a routerLink="/services-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/services-one" class="prev page-numbers"><i class="flaticon-left"></i></a>
                    <a routerLink="/services-one" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/services-one" class="page-numbers">3</a>
                    <a routerLink="/services-one" class="page-numbers">4</a>
                    <a routerLink="/services-one" class="next page-numbers"><i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="clients-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Moris Jacker</h3>
                    <span>Web Developer</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Alex Maxwel</h3>
                    <span>Agent Management</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Edmond Halley</h3>
                    <span>Web Designer</span>
                </div>
            </div>
        </div>
    </div>
</section>