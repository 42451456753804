<section class="footer-section pt-100 pb-70">
    <div class="container">
        
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <a class="navbar-brand" routerLink="/">
                            <img src="assets/img/logo.png" alt="logo">
                        </a>
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Important Links</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/projects">Project</a></li>
                        <li><a routerLink="/services">Services</a></li>                       
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>    
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Featured Service</h3>
                    </div>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/services">IT Management</a></li>
                        <li><a routerLink="/services">Development</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/services">IT Infrastructure</a></li>
                        <li><a routerLink="/services">Support Engineer</a></li>
                    </ul>
                </div>    
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="footer-heading">
                        <h3>Contact</h3>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-phone-call"></i>
                        <h3>Phone</h3>
                        <span><a href="tel:+27634795584">27634795584</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-envelope"></i>
                        <h3>Email</h3>
                        <span><a href="mailto:hello@avrax.com">info@craftnettechnologies.co.za</a></span>
                    </div>
                    <div class="footer-info-contact">
                        <i class="flaticon-pin"></i>
                        <h3>Address</h3>
                        <span><a href="#" target="_blank">Malanshof, 21984 South Africa</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>©2023 - <a href="https://craftnettechnologies.co.za/" target="_blank">Craftnet Technologies</a></p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="go-top"><i class="bx bx-chevron-up"></i><i class="bx bx-chevron-up"></i></div>

<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h3><span>About Us</span>  <span></span> </h3>
                <p>We make your digital transformation journey super simple, safe, and aligned with your specific business needs. We're good at strategy planning for both enterprises and SMBs, IT audit, business process analysis, and more.</p>
       
                <p>Build customized business software solutions that perform. We can cover you along the whole way or fill in specific skill gaps in business analysis, UX/UI, testing, development, or support across web, mobile, desktop, cloud, and embedded platforms.</p>
               
            </div>
        </div>
        <div class="sidebar-contact-feed">
            <h2>Contact</h2>
            <div class="contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="send-btn">
                                <button type="submit" class="send-btn-one">Send Message</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="sidebar-contact-area">
            <div class="contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:+2763479558">+27 63 479 5584</a>
                        <span>OR</span>
                        <a href="mailto:hello@avrax.com">info@craftnettechnologies.co.za</a>
                    </h2>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-pinterest"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <span class="close-btn sidebar-modal-close-btn">
            <i class="flaticon-cancel"></i>
        </span>
    </div>
</div>