<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="protfolio-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img3.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Creative Web Develop</h3>
                        <span>Web Design</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img2.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Digital Services</h3>
                        <span>App Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Complex Design</h3>
                        <span>Software Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img4.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Digital Services</h3>
                        <span>E-commerce Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img4.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>IT infrastructure</h3>
                        <span>IT infrastructure design and implementation </span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Cyber</h3>
                        <span>Cybersecurity and risk management</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                
            </div>
        </div>
    </div>
</section>