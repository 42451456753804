<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>We offer advisory, strategic, and technical services to our clients optimize their IT infrastructure, systems, and processes.<br/><br/></p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web App Development</h3>
                    <p>We develop custom software solutions tailored to the client's specific requirements, as well as integrating and customizing existing software applications..</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>IT strategy and planning</h3>
                    <p>Assisting clients in developing a strategic roadmap for their IT initiatives, aligning technology with business goals, and identifying areas for improvement..</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>App Development</h3>
                    <p>We develop custom software solutions tailored to the client's specific requirements, as well as integrating and customizing existing software applications..</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>Cybersecurity and risk management </h3>
                    <p>We assess and mitigate cybersecurity risks, implementing security measures and protocols, and providing ongoing monitoring and support to protect against potential threats.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>IT infrastructure</h3>
                    <p>We Help clients design, build, and implement robust and scalable IT infrastructure, including network architecture, hardware and software solutions, and cloud computing..</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="faq-section pb-100">
    <div class="container">
        <div class="section-title">
           
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            
        </div>
    </div>
</section>