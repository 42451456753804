<app-navbar-one></app-navbar-one>

<div class="main-banner-area-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h1>Be Unique With Avrax IT Startup</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing incididunt ut laboredolore magna aliqua elsed  tempomet, consectetur adipiscing.</p>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">Get Started</a>
                                <a routerLink="/contact" class="optional-btn">Contact Us</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/home-two/1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-two/2.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-two/3.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-two/4.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-two/5.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/home-two/main.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.6s" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape"><img src="assets/img/home-two/cloud.png" alt="image"></div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>

<section class="features-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Creative <span>Features</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-blueprint"></i>
                    </div>
                    <h3>Great Design</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-update"></i>
                    </div>
                    <h3>Optimal Choice</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-security"></i>
                    </div>
                    <h3>Finest Quality</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="features-content">
                    <div class="icon">
                        <i class="flaticon-clock"></i>
                    </div>
                    <h3>Time Saving</h3>
                    <p>Lorem ipsum dolor sit amet consecr adipiscing elit sed do eiusmod tempor incididunt</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="work-section pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="work-content">
                    <h3>Brainstorming, <span>Researching</span> Planning, <span>Strategizing</span> Work</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed dood tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.</p>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="work-status">
                                <h3><span class="odometer" data-count="50">00</span><span class="sign-icon">K</span></h3>
                                <h4>Completed Project</h4>
                                <p>Lorem ipsum dolor sit amet consectetur do eiusmod tempor incididunt ut labore et dolore.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="work-status">
                                <h3><span class="odometer" data-count="98">00</span><span class="sign-icon">%</span></h3>
                                <h4>Customer Satisfaction</h4>
                                <p>Lorem ipsum dolor sit amet consectetur do eiusmod tempor incididunt ut labore et dolore.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0">
                <div class="work-image">
                    <img src="assets/img/work-bg.jpg" alt="image">
                    <a href="https://www.youtube.com/watch?v=YymWhauqjoA" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-section bg-background pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Digital Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>App Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>E-commerce Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>IT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore facilisis.</p>
                    <a routerLink="/services-details" class="read-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="support-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="support-image">
                    <img src="assets/img/support.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="support-content">
                    <div class="icon">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Amazing Support</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua.</p>
                </div>
                <div class="support-content">
                    <div class="icon">
                        <i class="flaticon-internet"></i>
                    </div>
                    <h3>Big Data Analysis</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua.</p>
                </div>
                <div class="support-content">
                    <div class="icon">
                        <i class="flaticon-cloud-computing"></i>
                    </div>
                    <h3>Cloud Computing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overview-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-edit"></i>
                    </div>
                    <h3>Sketch</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-thinking"></i>
                    </div>
                    <h3>Design</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3>Test</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-software"></i>
                    </div>
                    <h3>Develop</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Why Choose Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-shared-folder"></i>
                    </div>
                    <h3>Consulting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-laptop"></i>
                    </div>
                    <h3>Data Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="image">
                    <img src="assets/img/mobile.png" alt="image">
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>Page Ranking</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>
                    <h3>Location Targeting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="fun-facts-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-check"></i>
                    </div>
                    <h3 class="odometer" data-count="950">00</h3>
                    <p>Completed Project</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="150">00</span></h3>
                    <p>Happy Clients</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="550">00</span></h3>
                    <p>Multi Service</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="750">00</span></h3>
                    <p>Winning Awards</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="projects-section pt-100 pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Projects</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img8.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Update & Rebrand</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img9.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Consultancy</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img10.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Digital Marketing</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img11.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>App Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img12.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>IT Solutions</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img13.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Data Management</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img14.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>E-commerce Development</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 p-0">
                <div class="single-projects-box two">
                    <div class="projects-image">
                        <img src="assets/img/projects/img15.jpg" alt="image">
                    </div>
                    <div class="projects-content">
                        <h3>Creative Web Develop</h3>
                        <span>Research and Startup</span>
                    </div>
                    <a routerLink="/projects-details" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-section pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/img1.jpg" alt="image">
                    </div>
                    <div class="content">
                        <h3>David Jon Korola</h3>
                        <span>Web Developer</span>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/img2.jpg" alt="image">
                    </div>
                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>Software Engineer</span>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/img3.jpg" alt="image">
                    </div>
                    <div class="content">
                        <h3>Louis Pasteur</h3>
                        <span>App Developer</span>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="skills-section">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 pl-0">
                <div class="skills-image">
                    <img src="assets/img/skills.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="skills-area">
                    <div class="skills-content">
                        <h3>How to Generate <span>Creative</span> Ideas for your <span>IT</span> Business</h3>
                        <div class="bar"></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus do  umsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the industry. Lorem Ipsum has been the industry's.</p>
                    </div>
                    <div class="skill-bar" data-percentage="75%">
                        <p class="progress-title-holder">
                            <span class="progress-title">Developement</span>
                            <span class="progress-number-wrapper">
                                <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                </span>
                            </span>
                        </p>
                        <div class="progress-content-outter">
                            <div class="progress-content"></div>
                        </div>
                    </div>
                    <div class="skill-bar" data-percentage="45%">
                        <p class="progress-title-holder">
                            <span class="progress-title">Design</span>
                            <span class="progress-number-wrapper">
                                <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                </span>
                            </span>
                        </p>
                        <div class="progress-content-outter">
                            <div class="progress-content"></div>
                        </div>
                    </div>
                    <div class="skill-bar" data-percentage="80%">
                        <p class="progress-title-holder">
                            <span class="progress-title">Marketing</span>
                            <span class="progress-number-wrapper">
                                <span class="progress-number-mark">
                                    <span class="percent"></span>
                                    <span class="down-arrow"></span>
                                </span>
                            </span>
                        </p>
                        <div class="progress-content-outter">
                            <div class="progress-content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Latest</span> News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>20 March 2023</span>
                        <h3><a routerLink="/blog-details">Great Tips To Earn More Money From Digital Industry</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img2.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>25 April 2023</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img3.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>30 July 2023</span>
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="clients-section bg-background ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Moris Jacker</h3>
                    <span>Web Developer</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Alex Maxwel</h3>
                    <span>Agent Management</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Edmond Halley</h3>
                    <span>Web Designer</span>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-section ptb-50">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <a routerLink="/"><img src="assets/img/partnar/1.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/"><img src="assets/img/partnar/2.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/"><img src="assets/img/partnar/3.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/"><img src="assets/img/partnar/4.png" alt="partner"></a>
            </div>
            <div class="partner-item">
                <a routerLink="/"><img src="assets/img/partnar/5.png" alt="partner"></a>
            </div>
        </div>
    </div>
</div>