import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-three',
  templateUrl: './navbar-three.component.html',
  styleUrls: ['./navbar-three.component.scss']
})
export class NavbarThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
